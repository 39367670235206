import { createSlice } from '@reduxjs/toolkit'
export const counterSlice = createSlice({
  name: 'app',
  initialState: {
    loader: 0,
    left_sidebar: window.innerWidth > 991 ? true : false,
    pickup_locations: null,
  },
  reducers: {
    loader: (state, action) => {
      if (action.payload) {
        state.loader++;
      } else {
        state.loader--;
      }
    },
    loaderStop: (state) => {
      state.loader = 0;  // stop loader 
    },
    sidebarCollapse: (state) => {
      state.left_sidebar = !state.left_sidebar;
    },
    setPickupLocations: (state, action) => {
      state.pickup_locations = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { loader, loaderStop, sidebarCollapse, setPickupLocations } = counterSlice.actions

export default counterSlice.reducer