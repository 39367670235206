import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  countries,
  isValidEmail,
  statndardPhoneNumber,
} from "../../services/helper";
import { useDispatch, useSelector } from "react-redux";
import { get, handleException, post } from "../../network/requests";
import { getUrl } from "../../network/url";
import { authHeader, setCurrentUser } from "../../services/auth";
import { authUser, setCountryCode } from "../../reducer/auth-reducer";
import { loader, loaderStop } from "../../reducer/app-reducer";
import { notify } from "../../services/react-toastify";
import { useForm } from "react-hook-form";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../firebaseConfig";
import { PatternFormat } from "react-number-format";
initializeApp(firebaseConfig);
const auth = getAuth();
const captcha = {
  size: "invisible",
};
if (!window.recaptchaVerifier) {
  window.recaptchaVerifier = new RecaptchaVerifier(
    auth,
    "recaptcha-container",
    captcha
  );
  window.recaptchaVerifier.render(); // render google recaptcha
}
export default function CustomerProfileEditMainComponent() {
  const user = useSelector((state) => state.auth.auth_user);
  const countryCode = useSelector((state) => state.auth.country_code);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileRef = useRef();
  const [tempProfile, setTempProfile] = useState(null);
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm();

  const getUserData = () => {
    get(getUrl("profile"), authHeader())
      .then((response) => {
        setCurrentUser(response.data.data); // update current user data in localstorage
        dispatch(authUser(response.data.data)); // update current user data in redux store
      })
      .catch((error) => {
        dispatch(loaderStop()); // stop all loaders
        if (!handleException(error) && error.response.data) {
          notify("error", error.response.data.message);
        }
      });
  };

  const handleProfileImage = (e) => {
    if (e.target.files[0]) {
      setTempProfile(URL.createObjectURL(e.target.files[0])); // set temporary profile image url
    } else {
      setTempProfile(null);
    }
  };

  const updateProfile = (data) => {
    dispatch(loader(true)); // start loader

    let form = new FormData();
    if (profileRef.current.files[0]) {
      form.append("profile_image", profileRef.current.files[0]);
    }
    form.append("first_name", data.first_name);
    form.append("last_name", data.last_name);
    form.append("phone_number", data.phone_number);
    form.append("country_code", data.country_code);
    form.append("email", data.email);

    post(getUrl("profile"), form, authHeader())
      .then((response) => {
        setCurrentUser(response.data.data); // update current user data in localstorage
        dispatch(authUser(response.data.data)); // update current user data in redux store
        dispatch(loader(false)); // stop loader
        navigate("/profile");
        notify("success", response.data.message);
      })
      .catch((error) => {
        dispatch(loaderStop()); // stop all loaders
        if (!handleException(error) && error.response.data) {
          notify("error", error.response.data.message);
        }
      });
  };

  // OTP send on phone number using firebase
  const sendOtp = (phone = "") => {
    dispatch(loader(true)); // start loader
    // verify phone number exist in database
    post(getUrl("verify-phone"), { phone_number: phone })
      .then((response) => {
        dispatch(loader(false)); // stop loader
        if (!response.data.data.details) {
          phone = `${countryCode}${phone}`; // add country code prefix on phone number
          let appVerifier = window.recaptchaVerifier;
          if (phone && phone.length > 9) {
            dispatch(loader(true)); // start loader
            signInWithPhoneNumber(auth, phone, appVerifier)
              .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                setVerifyOtp(true);
                dispatch(loader(false)); // stop loader
              })
              .catch((error) => {
                // Error; SMS not sent
                dispatch(loaderStop()); // stop all loaders
                notify("error", "Something went wrong. Please try again.");
                console.log(error.message);
              });
          } else {
            notify("error", "Something went wrong. Please try again.");
          }
        } else {
          notify("error", "The entered phone number has already been taken."); // prevent the OTP function if phone number already taken
        }
      })
      .catch((error) => {
        dispatch(loaderStop()); // stop all loaders
        if (!handleException(error) && error.response.data) {
          notify("error", error.response.data.message);
        }
      });
  };

  // verify firebase OTP
  const verifyPhone = (data) => {
    dispatch(loader(true)); // start loader
    let code = document.getElementById("phoneOTP2").value;
    window.confirmationResult
      .confirm(code)
      .then((result) => {
        // OTP verified successfully.
        console.log(result);
        dispatch(loader(false)); // stop loader
        updateProfile(data);
        setVerifyOtp(false); // hide the OTP input
        window.confirmationResult = null; // reset the otp verify request
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        console.log(error);
        dispatch(loaderStop()); // stop all loaders
        notify("error", "Bad verification code");
      });
  };

  const submitHandler = (data) => {
    data.phone_number = document.getElementById("phoneInput3").value;
    data.country_code = countryCode;

    if (verifyOtp) {
      verifyPhone(data); // verify phone number
    } else {
      if (data.phone_number === user.phone_number) {
        updateProfile(data); // Update Profile
      } else {
        setPhoneNumber(data.phone_number);
        sendOtp(data.phone_number); // send OTP
      }
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const checkValidPhone = () => {
    let val = document.getElementById("phoneInput3").value;
    return val.length === 10 ? true : false;
  };

  const checkOtp = () => {
    let val = document.getElementById("phoneOTP2").value;
    return val.length === 6 ? true : false;
  };
  return (
    <section className="dashboard-middle-main-section">
      <div className="container common-dashboard-container">
        <div className="head-area-div">
          <h1>Your Profile</h1>
        </div>

        <div className="profile-main-div">
          <div className="container common-container">
            <div className="row">
              <div className="col-xl-8 offset-xl-2">
                <div className="profile-inner-main-div">
                  <div className="container common-dashboard-container">
                    <div className="profile-picture-div">
                      <img
                        src={
                          tempProfile
                            ? tempProfile
                            : user && user.profile_image
                            ? user.profile_image
                            : "/images/user2.png"
                        }
                        alt="User"
                      />
                    </div>
                    <label htmlFor="upload-pro-pic" className="upload-pic-btn">
                      Update Photo
                      <input
                        type="file"
                        accept='image/*'
                        id="upload-pro-pic"
                        hidden
                        ref={profileRef}
                        onChange={(e) => handleProfileImage(e)}
                      />
                    </label>
                    <p className="user-name mt-3">{user.full_name}</p>
                    <p className="user-phone">
                      {statndardPhoneNumber(user.phone_number)}
                    </p>
                    <hr className="line-hr" />
                    <div className="container p-0-mob">
                      <div className="row">
                        <div className="col-xl-8 offset-xl-2">
                          <form
                            action=""
                            onSubmit={handleSubmit(submitHandler)}
                          >
                            <div className="row">
                              <div className="col-lg-6 mt-4">
                                <div className="each-input-main-div">
                                  <label htmlFor="fName">First Name</label>
                                  <input
                                    type="text"
                                    id="fName"
                                    {...register("first_name", {
                                      required: true,
                                      value: user.first_name,
                                    })}
                                    placeholder="First Name"
                                  />
                                </div>
                                {errors?.first_name?.type === "required" && (
                                  <label className="text-danger">
                                    This field is required.
                                  </label>
                                )}
                              </div>
                              <div className="col-lg-6 mt-4">
                                <div className="each-input-main-div">
                                  <label htmlFor="lName">Last Name</label>
                                  <input
                                    type="text"
                                    id="lName"
                                    {...register("last_name", {
                                      value: user.last_name,
                                    })}
                                    placeholder="Last Name"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 mt-4">
                                <div className="each-input-main-div">
                                  <label htmlFor="email">Email</label>
                                  <input
                                    type="text"
                                    id="email"
                                    {...register("email", {
                                      value: user.email,
                                      validate: isValidEmail,
                                    })}
                                    placeholder="Email"
                                  />
                                  {errors?.email?.type === "validate" && (
                                    <label className="text-danger">
                                      Please enter valid email address.
                                    </label>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-12 mt-4">
                                <div className="each-input-main-div">
                                  <label htmlFor="phone">Phone number</label>
                                  <input
                                    type="hidden"
                                    placeholder="Enter phone number"
                                    {...register("phone_number", {
                                      validate: checkValidPhone,
                                      value: user.phone_number,
                                    })}
                                    id="phoneInput3"
                                  />

                                  <div className="d-flex phone-with-code">
                                    <select
                                      className="phone-select"
                                      onChange={(e) =>
                                        dispatch(setCountryCode(e.target.value))
                                      }
                                      defaultValue={countryCode}
                                    >
                                      {countries().map((country, index) => (
                                        <option
                                          key={index}
                                          value={country.code}
                                        >
                                          {country.name}
                                        </option>
                                      ))}
                                    </select>
                                    <PatternFormat
                                      className="form-control phone-input"
                                      format="##########"
                                      allowEmptyFormatting
                                      value={user.phone_number}
                                      onValueChange={(e) => {
                                        document.getElementById(
                                          "phoneInput3"
                                        ).value = e.value;
                                        clearErrors("phone_number");
                                      }}
                                      readOnly={verifyOtp}
                                    />
                                  </div>

                                  {errors?.phone_number?.type ===
                                    "validate" && (
                                    <label className="text-danger">
                                      Please enter a valid phone number.
                                    </label>
                                  )}
                                </div>
                              </div>
                            </div>

                            <p className="info-para">
                              <svg
                                width="24"
                                height="25"
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.9998 17.6382H12.9998V11.6382H10.9998V17.6382ZM11.9998 9.63818C12.2831 9.63818 12.5208 9.54218 12.7128 9.35018C12.9048 9.15818 13.0004 8.92085 12.9998 8.63818C12.9998 8.35485 12.9038 8.11718 12.7118 7.92518C12.5198 7.73318 12.2824 7.63752 11.9998 7.63818C11.7164 7.63818 11.4788 7.73418 11.2868 7.92618C11.0948 8.11818 10.9991 8.35552 10.9998 8.63818C10.9998 8.92152 11.0958 9.15918 11.2878 9.35118C11.4798 9.54318 11.7171 9.63885 11.9998 9.63818ZM11.9998 22.6382C10.6164 22.6382 9.31642 22.3755 8.09976 21.8502C6.88309 21.3249 5.82476 20.6125 4.92476 19.7132C4.02476 18.8132 3.31242 17.7549 2.78776 16.5382C2.26309 15.3215 2.00042 14.0215 1.99976 12.6382C1.99976 11.2549 2.26242 9.95485 2.78776 8.73818C3.31309 7.52152 4.02542 6.46318 4.92476 5.56318C5.82476 4.66318 6.88309 3.95085 8.09976 3.42618C9.31642 2.90152 10.6164 2.63885 11.9998 2.63818C13.3831 2.63818 14.6831 2.90085 15.8998 3.42618C17.1164 3.95152 18.1748 4.66385 19.0748 5.56318C19.9748 6.46318 20.6874 7.52152 21.2128 8.73818C21.7381 9.95485 22.0004 11.2549 21.9998 12.6382C21.9998 14.0215 21.7371 15.3215 21.2118 16.5382C20.6864 17.7549 19.9741 18.8132 19.0748 19.7132C18.1748 20.6132 17.1164 21.3258 15.8998 21.8512C14.6831 22.3765 13.3831 22.6389 11.9998 22.6382ZM11.9998 20.6382C14.2331 20.6382 16.1248 19.8632 17.6748 18.3132C19.2248 16.7632 19.9998 14.8715 19.9998 12.6382C19.9998 10.4049 19.2248 8.51318 17.6748 6.96318C16.1248 5.41318 14.2331 4.63818 11.9998 4.63818C9.76642 4.63818 7.87476 5.41318 6.32476 6.96318C4.77476 8.51318 3.99976 10.4049 3.99976 12.6382C3.99976 14.8715 4.77476 16.7632 6.32476 18.3132C7.87476 19.8632 9.76642 20.6382 11.9998 20.6382Z"
                                  fill="#4D90FE"
                                />
                              </svg>
                              You need to verify the OTP in order to switch the
                              phone number.
                            </p>

                            {verifyOtp ? (
                              <div className="col-lg-12 mt-4">
                                <p className="text-para">
                                  A 6-digit code has been sent on your mobile
                                  number <b>{phoneNumber}</b> which will get
                                  expired in 10 minutes.{" "}
                                  <Link
                                    to=""
                                    onClick={() => setVerifyOtp(false)}
                                  >
                                    Change Phone Number
                                  </Link>
                                </p>
                                <div className="each-input-main-div">
                                  <label htmlFor="phoneOTP2">
                                    Enter the OTP
                                  </label>
                                  <input
                                    type="hidden"
                                    placeholder="Enter the OTP"
                                    id="phoneOTP2"
                                    {...register("otp", { validate: checkOtp })}
                                  />
                                  <PatternFormat
                                    className="form-control"
                                    format="######"
                                    allowEmptyFormatting
                                    onValueChange={(e) => {
                                      clearErrors("otp");
                                      document.getElementById(
                                        "phoneOTP2"
                                      ).value = e.value;
                                    }}
                                  />
                                  {errors?.otp?.type === "validate" && (
                                    <label className="text-danger">
                                      Please enter a valid OTP.
                                    </label>
                                  )}
                                </div>
                                <p className="didnot-get-otp-para">
                                  Didn’t get the OTP?{" "}
                                  <Link
                                    to=""
                                    onClick={() => sendOtp(phoneNumber)}
                                  >
                                    Resend OTP
                                  </Link>
                                </p>
                              </div>
                            ) : null}

                            <div className="update-cancel-btn-div">
                              <button className="update-btn">
                                {verifyOtp ? "SUBMIT" : "UPDATE DETAILS"}
                              </button>
                              <Link to="/profile" className="cancel-btn">
                                CANCEL
                              </Link>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
