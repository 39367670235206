import { createSlice } from '@reduxjs/toolkit'
import { authCheck, getCurrentUser } from '../services/auth';
export const counterSlice = createSlice({
    name: 'auth',
    initialState: {
        auth_user: authCheck() ? getCurrentUser() : null,
        access_token: authCheck() ? getCurrentUser().access_token : null,
        merchants: null,
        stats: null,
        trips: null,
        payments: null,
        country_code: (authCheck() && getCurrentUser().country_code) ? getCurrentUser().country_code : '+234',
    },
    reducers: {
        authUser: (state, action) => {
            state.auth_user = action.payload;
        },
        setStats: (state, action) => {
            state.stats = action.payload;
        },
        setTrips: (state, action) => {
            state.trips = action.payload;
        },
        setPayments: (state, action) => {
            state.payments = action.payload;
        },
        setMerchants: (state, action) => {
            state.merchants = action.payload;
        },
        setCountryCode: (state, action) => {
            state.country_code = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { authUser, setStats, setMerchants, setTrips, setPayments, setCountryCode } = counterSlice.actions

export default counterSlice.reducer