import React from 'react'

export default function DashboardFooter() {
    return (
        <div className="container common-dashboard-container dashboard-footer-main-div">
            <footer className="dashboard-footer">
                <p>fleetNG. Copyright {new Date().getFullYear()}. All Rights Reserved.</p>
            </footer>
        </div>
    )
}
