import React from 'react'
import Navbar from '../components/common/Navbar'
import { Link } from 'react-router-dom'

export default function TripPaymentFailedPage({transactionId}) {
    return (
        <div>
            <Navbar />
            <section className="thankyou-main-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-lg-2">
                            <img src="/images/icons/payment-error.svg" alt="Thank You" className="img-fluid" />
                            <h1>Sorry, something went wrong!</h1>
                            {/* <p className="tip-id">Trip ID: #{params.id}</p> */}
                            <p className="tip-id">Transaction ID: #{transactionId}</p>
                            <p className="text-para">Some error occurred while processing your payment request. Please try again or after sometime. Thanks!<br /><br /> Feel free to contact us for any kind of query!</p>
                            <Link to="/trip/add" className="request-another-btn">Request for Another Trip</Link>
                            <Link to="/" className="goto-dashboard">Go to your Dashboard</Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
