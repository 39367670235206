import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { loader, loaderStop, setPickupLocations } from '../../reducer/app-reducer';
import { get, handleException, post } from '../../network/requests';
import { getUrl } from '../../network/url';
import { authHeader } from '../../services/auth';
import { useNavigate } from 'react-router-dom';
import { notify } from '../../services/react-toastify';
export default function TripCreateFormComponent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const now = new Date();

    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    const pickupLocations = useSelector(state => state.app.pickup_locations);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const submitHandler = (data) => {
        dispatch(loader(true)); // start loader
        post(`${getUrl('draft-trips')}/create`, data, authHeader()).then((response) => {
            console.log(response.data);
            dispatch(loader(false)); // stop loader
            navigate(`/trip/${response.data.data.id}/driver`);
        }).catch((error) => {
            dispatch(loaderStop()); // stop all loaders
            if (!handleException(error) && error.response.data) {
                notify('error', error.response.data.message);
            }
        })
    }

    useEffect(() => {
        if (!pickupLocations) {
            get(getUrl('pickup-locations')).then((response) => {
                console.log(response.data);
                dispatch(setPickupLocations(response.data.data));
            }).catch((error) => {
                console.log(error);
            })
        }
    }, [])
    return (
        <form action="" onSubmit={handleSubmit(submitHandler)}>
            <div className="row">
                <div className="col-md-6">
                    <div className="each-input-main-div">
                        <label htmlFor="pickLocation">Pick-up Location</label>
                        {/* <input type="text" id="pickLocation" placeholder="Enter pick-up location" {...register("pickup_location", { required: true })} /> */}
                        {
                            <select name="" id=""  {...register("pickup_location", { required: true })} >
                                <option value="" hidden>Choose pick-up location</option>
                                {pickupLocations && pickupLocations.map(((item, index) => <option key={index} value={item.id}>{item.location_name || item.location}</option>))}
                            </select>
                        }
                    </div>
                    
                    {errors?.pickup_location?.type === "required" && <label className='text-danger'>This field is required.</label>}
                </div>
                <div className="col-md-6 mt-4 mt-md-0">
                    <div className="each-input-main-div">
                        <label htmlFor="dropLocation">Drop-off Location</label>
                        <input type="text" id="dropLocation" placeholder="Enter drop-off location"  {...register("drop_off_location", { required: true })} />
                    </div>
                    {errors?.drop_off_location?.type === "required" && <label className='text-danger'>This field is required.</label>}
                </div>
                <div className="col-md-6 mt-4">
                    <div className="each-input-main-div">
                        <label htmlFor="pickLocation">Pick-up Date & Time</label>
                        <input type="datetime-local" id="pickLocation" value={now.toISOString().slice(0, 16)}
                            placeholder="Enter the date and time"  {...register("pickup_datetime", { required: true })} readOnly />
                    </div>
                    {errors?.pickup_datetime?.type === "required" && <label className='text-danger'>This field is required.</label>}
                </div>
                <div className="col-md-6 mt-4">
                    <div className="each-input-main-div">
                        <label htmlFor="cost">Cost (in NGN)</label>
                        <input type="number" id="cost" placeholder="Enter the cost"  {...register("cost", { required: true })} />
                    </div>
                    {errors?.cost?.type === "required" && <label className='text-danger'>This field is required.</label>}
                </div>
            </div>

            <div className="bottom-btn-main-div">
                <button className="btn process-btn active">
                    Proceed
                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="23"
                        viewBox="0 0 34 23" fill="none">
                        <path
                            d="M33.3641 12.7079C33.9499 12.1221 33.9499 11.1723 33.3641 10.5866L23.8181 1.04061C23.2323 0.454826 22.2826 0.454826 21.6968 1.04061C21.111 1.6264 21.111 2.57615 21.6968 3.16193L30.1821 11.6472L21.6968 20.1325C21.111 20.7183 21.111 21.668 21.6968 22.2538C22.2826 22.8396 23.2324 22.8396 23.8181 22.2538L33.3641 12.7079ZM0.609863 13.1472L32.3034 13.1472L32.3034 10.1472L0.609863 10.1472L0.609863 13.1472Z"
                            fill="white" />
                    </svg>
                </button>
            </div>
        </form>
    )
}
