export const isValidEmail = (mail) => {
    // eslint-disable-next-line no-useless-escape
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true
    }
    return false;
}

export const countries = () => {
    let array = [
        { code: "+229", name: "Benin (+229)", },
        { code: "+226", name: "Burkina Faso (+226)", },
        { code: "+1", name: "Canada (+1)", },
        { code: "+238", name: "Cape Verde (+238)", },
        { code: "+225", name: "Côte D'Ivoire (+225)", },
        { code: "+33", name: "France (+33)", },
        { code: "+220", name: "Gambia (+220)", },
        { code: "+233", name: "Ghana (+233)", },
        { code: "+224", name: "Guinea (+224)", },
        { code: "+245", name: "Guinea-Bissau (+245)", },
        { code: "+91", name: "India (+91)", },
        { code: "+231", name: "Liberia (+231)", },
        { code: "+223", name: "Mali (+223)", },
        { code: "+222", name: "Mauritania (+222)", },
        { code: "+227", name: "Niger (+227)", },
        { code: "+234", name: "Nigeria (+234)", },
        { code: "+221", name: "Senegal (+221)", },
        { code: "+232", name: "Sierra Leone (+232)", },
        { code: "+228", name: "Togo (+228)", },
        { code: "+44", name: "UK (+44)", },
        { code: "+1", name: "USA (+1)", },
    ]
    return array;
}

export const statndardPhoneNumber = (phone = "") => {
    return `${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(6)}`;
}

