import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { notify } from '../../services/react-toastify';
import { removeAccessToken, setCurrentUser } from '../../services/auth';
import { authUser } from '../../reducer/auth-reducer';
import { sidebarCollapse } from '../../reducer/app-reducer';

export default function DashboardSidebar() {
    const left_sidebar = useSelector(state => state.app.left_sidebar);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const logout = () => {
        notify('success', 'Successfully logged out!')
        removeAccessToken(); // remove auth access token from localStorage
        setCurrentUser(null); // remove auth access token from localStorage
        dispatch(authUser(null)); // remove current user data from redux storage
        window.location.href = '/login'; // redirect to login page

        
    }
    const closeSidebar = () => {
        dispatch(sidebarCollapse());
    }

    const handleSidebarAndNavigate = (e, path) => {
        e.preventDefault();        
        navigate(`/${path}`, { replace: true });
        if(screen.width < 991.9){
            dispatch(sidebarCollapse());
        }        
    }    

    return (
        <div className={`dashboard-left-part ${left_sidebar ? '' : 'hide'}`} id="left-div">
            <div className="top-logo-div p-relative">
                <Link onClick={(e) => handleSidebarAndNavigate(e, "")} to="/">
                    <img src="/images/logo/fleetng-white-logo.svg" width="70" alt="Logo" />
                </Link>
                <button onClick={closeSidebar} className="btn sidebar-close-btn p-2"><svg width="28" height="28" fill="#fff" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg></button>
            </div>            

            <div className="all-dashboard-links">
                <Link onClick={(e) => handleSidebarAndNavigate(e, "")} to="/" className={location.pathname === "/" ? 'active' : ''}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                        <g clipPath="url(#clip0_183_516)">
                            <path
                                d="M12.9126 7.60082L12.9226 7.58982M16.9126 9.60082L16.9226 9.58982M8.91261 9.60082L8.92261 9.58982M18.9126 13.6008L18.9226 13.5898M6.91261 13.6008L6.92261 13.5898M17.9126 17.6008L17.9226 17.5898M7.91261 17.6008L7.92261 17.5898M12.9126 17.5908L13.9126 11.5908M9.41261 20.5918H4.91261C3.61132 18.8621 2.9092 16.7554 2.91261 14.5908C2.91261 9.06782 7.38961 4.59082 12.9126 4.59082C18.4356 4.59082 22.9126 9.06782 22.9126 14.5908C22.9126 16.8428 22.1686 18.9208 20.9126 20.5918L16.4126 20.5908"
                                stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path
                                d="M12.9126 23.5908C13.7082 23.5908 14.4713 23.2748 15.0339 22.7121C15.5965 22.1495 15.9126 21.3865 15.9126 20.5908C15.9126 19.7952 15.5965 19.0321 15.0339 18.4695C14.4713 17.9069 13.7082 17.5908 12.9126 17.5908C12.1169 17.5908 11.3539 17.9069 10.7913 18.4695C10.2287 19.0321 9.9126 19.7952 9.9126 20.5908C9.9126 21.3865 10.2287 22.1495 10.7913 22.7121C11.3539 23.2748 12.1169 23.5908 12.9126 23.5908Z"
                                stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_183_516">
                                <rect width="24" height="24" fill="white" transform="translate(0.912598 0.59082)" />
                            </clipPath>
                        </defs>
                    </svg>
                    Dashboard</Link>
                <Link onClick={(e) => handleSidebarAndNavigate(e, "payments")} to="/payments" className={location.pathname.includes('payments') ? 'active' : ''}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                        <g clipPath="url(#clip0_183_523)">
                            <path
                                d="M12.8349 1.50394C12.9742 1.32966 13.1466 1.18473 13.3423 1.07755C13.538 0.970371 13.7529 0.903065 13.9748 0.87953C14.1967 0.855996 14.421 0.876698 14.6348 0.940441C14.8486 1.00418 15.0476 1.1097 15.2204 1.25088L20.7235 5.74519C21.0262 5.99229 21.2337 6.33678 21.3106 6.71984C21.3875 7.1029 21.3291 7.50079 21.1453 7.84557C20.6642 7.69852 20.1638 7.62404 19.6607 7.62457H19.1546L19.6573 7.05097L16.759 4.68403L14.3684 7.62625H12.1938L15.4515 3.61782L14.1525 2.55666L10.1069 7.62457H7.94748L12.8349 1.50394ZM17.1301 16.0598C16.9064 16.0598 16.6918 16.1487 16.5336 16.3069C16.3755 16.4651 16.2866 16.6796 16.2866 16.9034C16.2866 17.1271 16.3755 17.3416 16.5336 17.4998C16.6918 17.658 16.9064 17.7469 17.1301 17.7469H18.8172C19.0409 17.7469 19.2554 17.658 19.4136 17.4998C19.5718 17.3416 19.6607 17.1271 19.6607 16.9034C19.6607 16.6796 19.5718 16.4651 19.4136 16.3069C19.2554 16.1487 19.0409 16.0598 18.8172 16.0598H17.1301ZM4.47721 8.46809C4.47721 8.24438 4.56608 8.02982 4.72428 7.87163C4.88247 7.71344 5.09702 7.62457 5.32074 7.62457H6.26212L7.60332 5.93751H5.32074C4.64959 5.93751 4.00593 6.20413 3.53135 6.6787C3.05677 7.15328 2.79016 7.79694 2.79016 8.46809V18.5904C2.79016 19.709 3.23452 20.7818 4.02548 21.5727C4.81644 22.3637 5.88921 22.808 7.00779 22.808H19.6607C20.5556 22.808 21.4138 22.4526 22.0465 21.8198C22.6793 21.187 23.0348 20.3288 23.0348 19.4339V12.6857C23.0348 11.7909 22.6793 10.9326 22.0465 10.2999C21.4138 9.6671 20.5556 9.31162 19.6607 9.31162H5.32074C5.09702 9.31162 4.88247 9.22275 4.72428 9.06456C4.56608 8.90636 4.47721 8.69181 4.47721 8.46809ZM4.47721 18.5904V10.8553C4.74039 10.9481 5.02551 10.9987 5.32074 10.9987H19.6607C20.1081 10.9987 20.5372 11.1764 20.8536 11.4928C21.17 11.8092 21.3477 12.2383 21.3477 12.6857V19.4339C21.3477 19.8814 21.17 20.3105 20.8536 20.6269C20.5372 20.9432 20.1081 21.121 19.6607 21.121H7.00779C6.33664 21.121 5.69298 20.8544 5.2184 20.3798C4.74383 19.9052 4.47721 19.2616 4.47721 18.5904Z"
                                fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_183_523">
                                <rect width="23.7624" height="24" fill="white"
                                    transform="translate(0.635376 0.591309)" />
                            </clipPath>
                        </defs>
                    </svg>
                    Payments
                </Link>
                <Link onClick={(e) => handleSidebarAndNavigate(e, "profile")} to="/profile" className={location.pathname.includes('profile') ? 'active' : ''}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                        <g clipPath="url(#clip0_183_528)">
                            <path
                                d="M12.9125 12.9786C11.7641 12.9786 10.6414 12.6381 9.68658 12.0001C8.73172 11.362 7.98749 10.4552 7.54801 9.39421C7.10854 8.33322 6.99355 7.16574 7.21759 6.03939C7.44164 4.91305 7.99465 3.87844 8.80669 3.0664C9.61874 2.25435 10.6534 1.70134 11.7797 1.47729C12.906 1.25325 14.0735 1.36824 15.1345 1.80772C16.1955 2.24719 17.1023 2.99142 17.7404 3.94629C18.3784 4.90115 18.7189 6.02377 18.7189 7.17218C18.7189 8.71214 18.1072 10.189 17.0183 11.278C15.9293 12.3669 14.4524 12.9786 12.9125 12.9786ZM12.9125 3.68831C12.2234 3.68831 11.5499 3.89263 10.9769 4.27544C10.404 4.65826 9.95748 5.20236 9.6938 5.83896C9.43011 6.47555 9.36112 7.17604 9.49555 7.85185C9.62997 8.52765 9.96178 9.14842 10.449 9.63564C10.9362 10.1229 11.557 10.4547 12.2328 10.5891C12.9086 10.7235 13.6091 10.6545 14.2457 10.3909C14.8823 10.1272 15.4264 9.68063 15.8092 9.10771C16.192 8.53479 16.3963 7.86122 16.3963 7.17218C16.3963 6.2482 16.0293 5.36206 15.3759 4.70871C14.7226 4.05536 13.8365 3.68831 12.9125 3.68831ZM23.7512 23.8173C23.4444 23.8133 23.1514 23.6897 22.9345 23.4728C22.7175 23.2558 22.5939 22.9628 22.5899 22.656C22.5899 19.6367 20.9486 17.6238 12.9125 17.6238C4.87635 17.6238 3.23506 19.6367 3.23506 22.656C3.23506 22.964 3.11271 23.2594 2.89492 23.4772C2.67714 23.695 2.38176 23.8173 2.07377 23.8173C1.76577 23.8173 1.47039 23.695 1.25261 23.4772C1.03483 23.2594 0.912476 22.964 0.912476 22.656C0.912476 15.3012 9.32022 15.3012 12.9125 15.3012C16.5047 15.3012 24.9125 15.3012 24.9125 22.656C24.9085 22.9628 24.7848 23.2558 24.5679 23.4728C24.351 23.6897 24.0579 23.8133 23.7512 23.8173Z"
                                fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_183_528">
                                <rect width="23.7624" height="24" fill="white"
                                    transform="translate(0.635376 0.591309)" />
                            </clipPath>
                        </defs>
                    </svg>
                    Profile</Link>
            </div>
            <div className="bottom-logout-div">
                <Link to="" onClick={() => logout()}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                    viewBox="0 0 25 25" fill="none">
                    <path
                        d="M12.9085 3.13672H3.91248V21.1367H12.9125M17.4125 16.6367L21.9125 12.1367L17.4125 7.63672M8.91248 12.1327H21.9125"
                        stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                    Logout
                </Link>
            </div>
        </div>
    )
}
