import React from "react";
import Navbar from "../components/common/Navbar";
import { Link, useParams } from "react-router-dom";

export default function TripConfirmedPage() {
  const params = useParams();
  return (
    <div>
      <Navbar />
      <section className="thankyou-main-section">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-lg-2">
              <img
                src="/images/icons/thankyou.svg"
                alt="Thank You"
                className="img-fluid"
              />
              <h1>Your trip is confirmed!</h1>
              <p className="tip-id">Trip ID: #{params.id}</p>
              <p className="text-para">
                We have received your trip request. The driver will soon process
                your trip. You will get notified for the payment when your
                package will get delivered at the drop-off location. <br />
                <br /> Feel free to contact us for any kind of query!
              </p>
              <Link to="/trip/add" className="request-another-btn">
                Request for Another Trip
              </Link>
              <Link to="/" className="goto-dashboard text-primary">
                Go to Your Dashboard
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
