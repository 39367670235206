const firebaseConfig = {
  apiKey: "AIzaSyCR1LWirGXHP5GNl8rzrXV_2JQVo9o8ZVw",
  authDomain: "fleetng-7af66.firebaseapp.com",
  projectId: "fleetng-7af66",
  storageBucket: "fleetng-7af66.appspot.com",
  messagingSenderId: "269353762670",
  appId: "1:269353762670:web:8a559637ea5af419e6cb7f",
  measurementId: "G-49581M7358"
};

// const firebaseConfig = {
//   databaseURL: "https://fleetng-dev.firebaseio.com",
//   apiKey: "AIzaSyB47pUUSZYMEpZ5mJjgw_kGENYU-qx9D-4",
//   authDomain: "fleetng-dev.firebaseapp.com",
//   projectId: "fleetng-dev",
//   storageBucket: "fleetng-dev.appspot.com",
//   messagingSenderId: "165608098850",
//   appId: "1:165608098850:web:8a46f7eb02e7a49917ce7b",
//   measurementId: "G-SGKN41KFSL"
// };

export default firebaseConfig;
