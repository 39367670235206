import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { statndardPhoneNumber } from "../../services/helper";
import { getUrl } from "../../network/url";
import { authHeader, setCurrentUser } from "../../services/auth";
import { loaderStop } from "../../reducer/app-reducer";
import { get, handleException } from "../../network/requests";
import { notify } from "../../services/react-toastify";
import { authUser } from "../../reducer/auth-reducer";

export default function CustomerProfileMainComponent() {
  const user = useSelector((state) => state.auth.auth_user);
  const dispatch = useDispatch();

  const getUserData = () => {
    get(getUrl("profile"), authHeader())
      .then((response) => {
        setCurrentUser(response.data.data); // update current user data in localstorage
        dispatch(authUser(response.data.data)); // update current user data in redux store
      })
      .catch((error) => {
        dispatch(loaderStop()); // stop all loaders
        if (!handleException(error) && error.response.data) {
          notify("error", error.response.data.message);
        }
      });
  };

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <section className="dashboard-middle-main-section">
      <div className="container common-dashboard-container">
        <div className="head-area-div">
          <h1 className="mb-0">Your Profile</h1>
        </div>

        <div className="profile-main-div">
          <div className="container common-container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="profile-inner-main-div">
                  <div className="common-dashboard-container">
                    <div className="profile-picture-div">
                      <img
                        src={user.profile_image || "/images/user2.png"}
                        alt="User"
                      />
                    </div>

                    <p className="user-name mt-3">{user.full_name}</p>
                    <p className="text-center">{user.email}</p>
                    <p className="user-phone">
                      {statndardPhoneNumber(user.phone_number)}
                    </p>

                    <Link to="/profile/edit" className="edit-details-btn">
                      EDIT DETAILS{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="21"
                        viewBox="0 0 22 21"
                        fill="none"
                      >
                        <path
                          d="M7.8829 17.9046L1.32666 19.7404L3.16241 13.1841L15.1676 1.23719C15.3033 1.09842 15.4653 0.988159 15.6442 0.912882C15.8231 0.837605 16.0152 0.798828 16.2093 0.798828C16.4034 0.798828 16.5955 0.837605 16.7744 0.912882C16.9533 0.988159 17.1154 1.09842 17.251 1.23719L19.8298 3.83054C19.9664 3.96599 20.0748 4.12713 20.1487 4.30467C20.2227 4.48221 20.2608 4.67264 20.2608 4.86497C20.2608 5.05731 20.2227 5.24774 20.1487 5.42528C20.0748 5.60282 19.9664 5.76396 19.8298 5.8994L7.8829 17.9046Z"
                          stroke="#4D90FE"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Link>

                    <div className="notification-main-div mt-4 d-none">
                      <p>
                        <img src="/images/icons/like.svg" alt="Like" /> You’ve
                        successfully verified your phone number.
                      </p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                      >
                        <path
                          d="M15.8848 16.6252L1.69922 2.4397M15.8848 2.4397L1.69922 16.6252"
                          stroke="#212121"
                          strokeWidth="3"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
