import React from 'react'
import { useForm } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { handleException, post } from '../../network/requests';
import { getUrl } from '../../network/url';
import { useDispatch, useSelector } from 'react-redux';
import { loader, loaderStop } from '../../reducer/app-reducer';
import { notify } from '../../services/react-toastify';
import { setCountryCode } from '../../reducer/auth-reducer';
import { countries } from '../../services/helper';

export default function ExistingUserForm({ active, sendOtp, setPhoneNumber }) {
    const dispatch = useDispatch();
    const countryCode = useSelector((state) => state.auth.country_code);

    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
    } = useForm();

    const handleExistingUser = () => {
        let phone = document.getElementById('phoneInput1').value;
        setPhoneNumber(phone);
        dispatch(loader(true)); // start loader

        // verify phone number from API
        post(getUrl('verify-phone'), { phone_number: phone }).then((response) => {
            dispatch(loader(false)); // stop loader
            if (response.data.data.details) {
                sendOtp(phone); // send OTP on phone number
            } else {
                notify('error', 'Please enter a valid phone number.');
            }
        }).catch((error) => {
            dispatch(loaderStop()); // stop all loaders
            if (!handleException(error) && error.response.data) {
                notify('error', error.response.data.message);
            }
        });
    };

    const checkValidPhone = () => {
        let val = document.getElementById('phoneInput1').value;
        return val.length === 10 ? true : false;
    }
    return (
        <form className={`tab-pane fade ${active ? 'show active' : ''}`} id="existing-user" role="tabpanel" aria-labelledby="existing-user-tab" onSubmit={handleSubmit(handleExistingUser)}>
            <div className="row">
                <div className="col-lg-12 d-flex justify-content-center d-lg-block">
                    <div className="each-input-main-div w-100-mob">
                        <label htmlFor="phoneInput1">Phone number</label>
                        <input type="hidden" id="phoneInput1" placeholder="Enter phone number" {...register("phone_number", { validate: checkValidPhone })} />
                        <div className="d-flex phone-with-code">
                            <select className='phone-select' onChange={(e) => dispatch(setCountryCode(e.target.value))} defaultValue={countryCode}>
                                {countries().map((country, index) => <option key={index} value={country.code}>{country.name}</option>)}
                            </select>
                            <PatternFormat className="form-control phone-input" format="##########" allowEmptyFormatting
                                onValueChange={(e) => {
                                    document.getElementById('phoneInput1').value = e.value;
                                    clearErrors('phone_number');
                                }}
                            />
                        </div>
                    </div>
                </div>
                    {errors?.phone_number?.type === "validate" && <label className='text-danger text-center text-lg-start mt-2'>Please enter a valid phone number.</label>}
            </div>
            <div className="bottom-btn-main-div justify-content-center justify-content-lg-between">
                <button type="submit" className="btn process-btn active">
                    Get OTP
                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="23"
                        viewBox="0 0 34 23" fill="none">
                        <path
                            d="M33.3641 12.7079C33.9499 12.1221 33.9499 11.1723 33.3641 10.5866L23.8181 1.04061C23.2323 0.454826 22.2826 0.454826 21.6968 1.04061C21.111 1.6264 21.111 2.57615 21.6968 3.16193L30.1821 11.6472L21.6968 20.1325C21.111 20.7183 21.111 21.668 21.6968 22.2538C22.2826 22.8396 23.2324 22.8396 23.8181 22.2538L33.3641 12.7079ZM0.609863 13.1472L32.3034 13.1472L32.3034 10.1472L0.609863 10.1472L0.609863 13.1472Z"
                            fill="white" />
                    </svg>
                </button>
            </div>
        </form>
    )
}
