import React from 'react'
import { useForm } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { loader, loaderStop } from '../../reducer/app-reducer';
import { handleException, post } from '../../network/requests';
import { getUrl } from '../../network/url';
import { setAccessToken, setCurrentUser } from '../../services/auth';
import { authUser } from '../../reducer/auth-reducer';
import { notify } from '../../services/react-toastify';

export default function OTPVerifyMainComponent({ verifyOtp, sendOtp, setVerifyOtp, userPreference, email = "", phoneNumber = "", firstName, lastName }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParamas] = useSearchParams();
    const countryCode = useSelector((state) => state.auth.country_code);

    const {
        register,
        handleSubmit,
        clearErrors,
        formState: { errors }
    } = useForm();

    const submitHandler = () => {
        dispatch(loader(true)); // start loader
        let code = document.getElementById('phoneOTP').value;
        window.confirmationResult.confirm(code).then(() => {
            // OTP verified successfully.
            if (userPreference === 'existing') {
                loginUser();
            } else {
                createNewUser();
            }
        }).catch((error) => {
            // User couldn't sign in (bad verification code?)
            console.log(error);
            dispatch(loaderStop()); // stop all loaders
            notify('error', 'Bad verification code');
        });
    };

    const checkOtp = () => {
        let val = document.getElementById('phoneOTP').value;
        return val.length === 6 ? true : false;
    }

    const createNewUser = () => {
        let data = {
            phone_number: phoneNumber,
            country_code: countryCode,
            first_name: firstName,
            last_ame: lastName,
            email: email
        };

        post(getUrl('register'), data).then((response) => {
            console.log(response.data);
            dispatch(loader(false)); // stop loader
            setAccessToken(response.data.data.access_token);  // set user access token in localStorage
            setCurrentUser(response.data.data); // set current user data in localStoreage
            dispatch(authUser(response.data.data)); // set current user data in redux storage
            navigate('/trip/add', { state: { type:"phone_verified"} }); // redirect to trips page with message type
        }).catch((error) => {
            dispatch(loaderStop()); // stop all loaders
            if (!handleException(error) && error.response.data) {
                notify('error', error.response.data.message);
            }
        });
    }

    const loginUser = () => {
        post(getUrl('login'), { phone_number: phoneNumber }).then((response) => {
            console.log(response.data);
            dispatch(loader(false)); // stop loader
            setAccessToken(response.data.data.access_token);  // set user access token in localStorage
            setCurrentUser(response.data.data); // set current user data in localStoreage
            dispatch(authUser(response.data.data)); // set current user data in redux storage
            if(searchParamas.get('next')){
                navigate(searchParamas.get('next')); // redirect to next page URL
            }else{
                navigate('/'); // redirect to dashboard page
            }
        }).catch((error) => {
            dispatch(loaderStop()); // stop all loaders
            if (!handleException(error) && error.response.data) {
                notify('error', error.response.data.message);
            }
        });
    }

    return (
        <div className={`col-lg-6 ${verifyOtp ? '' : 'd-none'}`} >
            <div className="container p-0-mob">
                <div className="right-part">
                    <p className="head-para">#1 Verify the OTP</p>
                    <p className="text-para">A 6-digit code has been sent on your mobile number <b>{phoneNumber}</b> which
                        will get expired in 10 minutes. <Link to="" onClick={() => setVerifyOtp(false)}>Change Phone Number</Link>
                    </p>
                    <form action="" onSubmit={handleSubmit(submitHandler)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="each-input-main-div">
                                    <label>Enter the OTP</label>
                                    <input type="hidden" placeholder="Enter the OTP" id='phoneOTP' {...register("otp", { validate: checkOtp })} />
                                    <PatternFormat className="form-control" format="######" allowEmptyFormatting
                                        onValueChange={(e) => {
                                            clearErrors('otp');
                                            document.getElementById('phoneOTP').value = e.value;
                                        }}
                                    />
                                    {errors?.otp?.type === "validate" && <label className='text-danger'>Please enter a valid OTP.</label>}
                                </div>
                                <p className="didnot-get-otp-para">Didn’t get the OTP? <a onClick={() => sendOtp(phoneNumber)}>Resend OTP</a></p>
                            </div>
                        </div>
                        <div className="bottom-btn-main-div">
                            <button className="btn process-btn active">
                                Proceed
                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="23" viewBox="0 0 34 23"
                                    fill="none">
                                    <path
                                        d="M33.3641 12.7079C33.9499 12.1221 33.9499 11.1723 33.3641 10.5866L23.8181 1.04061C23.2323 0.454826 22.2826 0.454826 21.6968 1.04061C21.111 1.6264 21.111 2.57615 21.6968 3.16193L30.1821 11.6472L21.6968 20.1325C21.111 20.7183 21.111 21.668 21.6968 22.2538C22.2826 22.8396 23.2324 22.8396 23.8181 22.2538L33.3641 12.7079ZM0.609863 13.1472L32.3034 13.1472L32.3034 10.1472L0.609863 10.1472L0.609863 13.1472Z"
                                        fill="white" />
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
