import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { get, handleException } from '../../network/requests';
import { getUrl } from '../../network/url';
import { authHeader } from '../../services/auth';
import { loader, loaderStop } from '../../reducer/app-reducer';
import { notify } from '../../services/react-toastify';
import dateFormat from 'dateformat';
import TripPaymentButton from '../../pages/TripPaymentButton';
const getBaseUrl = () => process.env.REACT_APP_API_URL;

export default function SingleTripMainComponent() {
    const trips = useSelector(state => state.auth.trips);
    const [trip, setTrip] = useState([]);
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getTripData = () => {
        if (trips) {
            let singleTrip = trips.filter(i => i.id === Number(params.id))[0];
            setTrip(singleTrip || []); // set trip data
        } else {
            dispatch(loader(true)); // start loaderpickup_location_alias
            get(`${getUrl('trips')}/${params.id}`, authHeader()).then((response) => {
                setTrip(response.data.data);
                dispatch(loader(false)); // start loader
            }).catch((error) => {
                dispatch(loaderStop()); // stop all loaders
                if (!handleException(error) && error.response.data) {
                    notify('error', error.response.data.message);
                }
            })
        }
    }

    useEffect(() => {
        getTripData();  // get single trip data from 
    }, [])

    return (
        <section className="dashboard-middle-main-section">
            <div className="container common-dashboard-container">
                <div className="head-area-div">
                    <h1>Trip #{trip.id}/ {dateFormat(trip.pick_up_datetime, 'mmmm d, yyyy h:MM TT')}</h1>
                    <button className="back-btn btn" onClick={() => navigate(-1)}><svg xmlns="http://www.w3.org/2000/svg" width="34" height="24"
                        viewBox="0 0 34 24" fill="none">
                        <path
                            d="M0.906917 13.0665C0.321133 12.4807 0.321133 11.531 0.906918 10.9452L10.4529 1.39926C11.0386 0.813469 11.9884 0.813469 12.5742 1.39926C13.16 1.98504 13.16 2.93479 12.5742 3.52058L4.0889 12.0059L12.5742 20.4911C13.16 21.0769 13.16 22.0267 12.5742 22.6125C11.9884 23.1982 11.0386 23.1982 10.4529 22.6125L0.906917 13.0665ZM33.6611 13.5059L1.96758 13.5059L1.96758 10.5059L33.6611 10.5059L33.6611 13.5059Z"
                            fill="#5B5B5B" />
                    </svg> Back</button>
                </div>

                <div className="all-details-main-div">
                    <div className="container-fluid px-0">
                        <div className="row">
                            <div className="col-md-4 each-detail-div">
                                <label>Pick-up </label>
                                <p>{trip.pickup_location_alias || trip.location_alias} </p>
                            </div>
                            <div className="col-md-4 each-detail-div">
                                <label>Drop-off Location</label>
                                <p>{trip.drop_location}</p>
                            </div>
                            <div className="col-md-4 each-detail-div">
                                <label>Pick-up Date & Time</label>
                                <p>{dateFormat(trip.pick_up_datetime, 'mmmm d, yyyy h:MM TT')}</p>
                            </div>
                            <div className="col-md-4 each-detail-div">
                                <label>Trip Created On</label>
                                <p>{dateFormat(trip.created_at, 'mmmm d, yyyy h:MM TT')}</p>
                            </div>
                            <div className="col-md-4 each-detail-div">
                                <label>Merchant</label>
                                <p className="fw-500">{trip.merchant}</p>
                            </div>
                            <div className="col-md-4 each-detail-div">
                                <label>Driver</label>
                                <p className="fw-500">{trip.driver}</p>
                            </div>
                            <div className="col-md-4 each-detail-div">
                                <label>Truck</label>
                                <p className="fw-500">{trip.truck_number}</p>
                            </div>
                            <div className="col-md-4 each-detail-div">
                                <label>Cost </label>
                                <p className="fw-500">{Number(trip.total_cost || 0).toLocaleString()} NGN</p>
                            </div>
                            <div className="col-md-4 each-detail-div">
                                <label>Clocked-In / Clocked-out</label>
                                <p className="fw-500">{trip.clock_in_time ? dateFormat(trip.clock_in_time, 'mmmm d, yyyy h:MM TT') : '--'} / {trip.clock_out_time ? dateFormat(trip.clock_out_time, 'mmmm d, yyyy h:MM TT') : '--'}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 each-detail-div">
                                <label>Trip Status</label>
                                <p className={trip.status ? trip.status.toLowerCase() : ""}>{trip.status}</p>
                            </div>
                            <div className="col-md-4 each-detail-div">
                                <label>Payment Status</label>
                                {trip.payment ? <p className="paid">{trip.paid_by_cash ? 'Paid By Cash':'Paid'}</p> : <p className="na">--</p>}
                            </div>
                            {trip.payment ? <>
                                <div className="col-md-4 each-detail-div">
                                    <label>Transaction Id / Date Time</label>
                                    <p className="">{trip.payment.transaction_id} / {dateFormat(trip.payment.created_at, 'mmmm d, yyyy h:MM TT')}</p>
                                    <p><a href={`${getBaseUrl()}/trip/${trip.id}/invoice/pdf-download`}>Download Invoice</a></p>
                                </div>
                            </> : null}
                        </div>
                        
                    </div>
                    {(trip && trip.id && trip.status !== "Canceled" && trip.status !== "Declined" && !trip.payment) ? <div className="pay-now-and-noty-div">
                        <TripPaymentButton tripId={trip.id} amount={trip.total_cost} tripStatus={trip.status} className='pay-now-btn active' />
                        <p className="info-para"><span><img src="/images/icons/info.svg" alt="Info" /></span>Payment will be raised once the trip is clocked-out by the driver.</p>
                    </div> : null}
                </div>

            </div>
        </section>
    )
}
