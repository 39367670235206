import React from 'react'
import { useForm } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { handleException, post } from '../../network/requests';
import { getUrl } from '../../network/url';
import { useDispatch, useSelector } from 'react-redux';
import { loader, loaderStop } from '../../reducer/app-reducer';
import { notify } from '../../services/react-toastify';
import { countries, isValidEmail } from '../../services/helper';
import { setCountryCode } from '../../reducer/auth-reducer';

export default function NewUserForm({ active, sendOtp, setEmail, setPhoneNumber, setFirstName, setLastName }) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
    } = useForm();

    const dispatch = useDispatch();
    const countryCode = useSelector((state) => state.auth.country_code);
    const handleNewUser = (data) => {
        let phone = document.getElementById('phoneInput2').value;
        data.phone_number = phone;
        setPhoneNumber(phone);
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setEmail(data.email);
        setPhoneNumber(data.phone_number);
        dispatch(loader(true)); // start loader

        // verify phone number from API
        post(getUrl('verify-details'), data).then((response) => {
            let result = response.data.data;

            if (result.email && !result.phone_number) {
                notify('error', 'Your email has already been taken.');
            }

            if (result.phone_number && !result.email) {
                notify('error', 'Your phone number has already been taken.');
            }

            if (result.phone_number && result.email) {
                notify('error', 'Your email and phone number has already been taken.');
            }

            if (!result.phone_number && !result.email) {
                sendOtp(phone); // send OTP on phone number
            }
            dispatch(loader(false)); // stop loader
        }).catch((error) => {
            dispatch(loaderStop()); // stop all loaders
            if (!handleException(error) && error.response.data) {
                notify('error', error.response.data.message);
            }
        });
    };

    const checkValidPhone = () => {
        let val = document.getElementById('phoneInput2').value;
        return val.length === 10 ? true : false;
    }
    return (
        <form className={`tab-pane fade ${active ? 'show active' : ''}`} id="new-user" role="tabpanel" aria-labelledby="new-user-tab" onSubmit={handleSubmit(handleNewUser)}>
            <div className="row">
                <div className="col-lg-6">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="each-input-main-div">
                                <label htmlFor="fName">First Name</label>
                                <input type="text" id="fName" placeholder="First Name" {...register("first_name", { required: true })} />
                            </div>
                        </div>
                        {errors?.first_name?.type === "required" && <label className='text-danger'>First name field is required.</label>}
                        <div className="col-lg-6 mt-4 mt-lg-0">
                            <div className="each-input-main-div">
                                <label htmlFor="lName">Last Name</label>
                                <input type="text" id="lName" placeholder="Last Name" {...register("last_name")} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 mt-4">
                    <div className="each-input-main-div">
                        <label htmlFor="email">Email</label>
                        <input type="text" id="email"  {...register("email", { validate: isValidEmail })} placeholder="Email" />
                        {errors?.email?.type === "validate" && <label className='text-danger'>Please enter valid email address.</label>}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 mt-4">
                    <div className="each-input-main-div">
                        <label>Phone number</label>
                        <input type="hidden" placeholder="Enter phone number" {...register("phone_number", { validate: checkValidPhone })} id="phoneInput2" />
                        <div className="d-flex phone-with-code">
                            <select className='phone-select' onChange={(e) => dispatch(setCountryCode(e.target.value))} defaultValue={countryCode}>
                                {countries().map((country, index) => <option key={index} value={country.code}>{country.name}</option> )}
                            </select>
                            <PatternFormat className="form-control phone-input" format="##########" allowEmptyFormatting
                                onValueChange={(e) => {
                                    document.getElementById('phoneInput2').value = e.value;
                                    clearErrors('phone_number');
                                }}
                            />
                        </div>
                    </div>
                    {errors?.phone_number?.type === "validate" && <label className='text-danger'>Please enter a valid phone number.</label>}
                </div>
            </div>
            <div className="bottom-btn-main-div justify-content-center justify-content-lg-between">
                <button type="submit" className="btn process-btn active">
                    Get OTP
                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="23"
                        viewBox="0 0 34 23" fill="none">
                        <path
                            d="M33.3641 12.7079C33.9499 12.1221 33.9499 11.1723 33.3641 10.5866L23.8181 1.04061C23.2323 0.454826 22.2826 0.454826 21.6968 1.04061C21.111 1.6264 21.111 2.57615 21.6968 3.16193L30.1821 11.6472L21.6968 20.1325C21.111 20.7183 21.111 21.668 21.6968 22.2538C22.2826 22.8396 23.2324 22.8396 23.8181 22.2538L33.3641 12.7079ZM0.609863 13.1472L32.3034 13.1472L32.3034 10.1472L0.609863 10.1472L0.609863 13.1472Z"
                            fill="white" />
                    </svg>
                </button>
            </div>
        </form>
    )
}
