import React from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

export default function Navbar() {
    const user = useSelector((state) => state.auth.auth_user);
    const navigate = useNavigate();
    return (
        <nav className="nav-main">
            <div className="container common-container">
                <div className={`navbar-inner-div ${user ? 'justify-content-between' : 'justify-content-center'}`}>
                    <div className="left-div"> <Link to="/"><img src="/images/logo/fleetng-logo.svg" alt="Logo" width="70"
                        className="img-fluid" /></Link></div>
                    {user ? <div className="right-div">
                        <div className="user-img-name-div" onClick={() => navigate('/profile')}>
                            <div className="nav-pro-img-div">
                                <img src={user.profile_image || "/images/user2.png"}  className="img-fluid" width="185" height="58" />
                            </div>
                            <span>{user.full_name}</span>
                        </div>
                    </div> : null}
                </div>
            </div>
        </nav>
    )
}
