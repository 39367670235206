import React from 'react'
import TripPaymentCompletePage from './TripPaymentCompletePage';
import TripPaymentFailedPage from './TripPaymentFailedPage';

export default function TripPaymentResponsePage() {
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get('status');
    const transactionId = queryParams.get('transaction_id');
    return (
        <div>
            {status === 'successful' ? <TripPaymentCompletePage transactionId={transactionId} /> : <TripPaymentFailedPage transactionId={transactionId} />}
        </div>
    )
}
