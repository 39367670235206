import React, { Fragment } from "react";
import { loader, loaderStop } from "../reducer/app-reducer";
import { handleException, post } from "../network/requests";
import { getUrl } from "../network/url";
import { useDispatch } from "react-redux";
import { notify } from "../services/react-toastify";
import { authHeader } from "../services/auth";
import TripRunningPaymentConfirmation from "../components/common/TripRunningPaymentConfirmation";

export default function TripPaymentButton({
  tripId,
  buttonSize = "large",
  className = "btn btn-info cursor-pointer",
  tripStatus = ""
}) {
  const dispatch = useDispatch();

  const startPayment = () => {
    dispatch(loader(true)); // start loader
    post(
      `${getUrl("trips")}/payment-link`,
      { trip_ids: [tripId] },
      authHeader()
    )
      .then((response) => {
        dispatch(loader(false)); // stop loader
        if (response.data.data.link) {
          window.location.href = response.data.data.link;
        }
      })
      .catch((error) => {
        dispatch(loaderStop()); // stop all loaders
        if (!handleException(error) && error.response.data) {
          notify("error", error.response.data.message);
        }
      });
  };

  return (
    <Fragment>
      {buttonSize === "large" ? (
        <button className={className}  data-bs-toggle={tripStatus === 'Running' | tripStatus === 'New' ? 'modal' : ''} data-bs-target={tripStatus === 'Running' | tripStatus === 'New' ? `#tripPaymentConfirmation${buttonSize}${tripId}` : ''} onClick={() => tripStatus === 'Running' | tripStatus === 'New' ? false : startPayment()} >
          Pay Now
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="23"
            viewBox="0 0 34 23"
            fill="none"
          >
            <path
              d="M33.3641 12.693C33.9499 12.1072 33.9499 11.1574 33.3641 10.5717L23.8181 1.02572C23.2323 0.439934 22.2826 0.439934 21.6968 1.02572C21.111 1.61151 21.111 2.56125 21.6968 3.14704L30.1821 11.6323L21.6968 20.1176C21.111 20.7034 21.111 21.6531 21.6968 22.2389C22.2826 22.8247 23.2324 22.8247 23.8181 22.2389L33.3641 12.693ZM0.609863 13.1323L32.3034 13.1323L32.3034 10.1323L0.609863 10.1323L0.609863 13.1323Z"
              fill="#212121"
            />
          </svg>
        </button>
      ) : null}

      {buttonSize === "small" ? (
        <a className="cursor-pointer" data-bs-toggle={tripStatus === 'Running' | tripStatus === 'New' ? 'modal' : ''} data-bs-target={tripStatus === 'Running' | tripStatus === 'New' ? `#tripPaymentConfirmation${buttonSize}${tripId}` : ''} onClick={() => tripStatus === 'Running' | tripStatus === 'New' ? false : startPayment()}>Pay Now</a>
      ) : null}

      <TripRunningPaymentConfirmation modalId={`${buttonSize}${tripId}`} startPayment={startPayment}/>
    </Fragment>
  );
}
