/* eslint-disable operator-linebreak */
/* eslint-disable no-undef */
import axios from 'axios';
import { notify } from '../services/react-toastify';

const timeout = process.env.NODE_ENV === 'development' ? 300000 : 500000;
const api = axios.create({
  Accept: '*/*',
  timeout,
  headers: {
    // 'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  withCredentials: false,
  responseType: 'json',
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const errorCodes = ['ERR_BAD_RESPONSE'];

// handle axios error
export const handleException = (e) => {
  console.log(e);
  if (!e.response || errorCodes.includes(e.code)) {
    notify('error', e.message);
    return true;
  }
  return false;
}

export const get = async (url, getHeader = false) => {
  const response = await api.get(url, getHeader);
  return response;
};

export const post = async (url, data, getHeader = false) => {
  const response = await api.post(url, data, getHeader);
  return response;
};

export const put = async (url, data, getHeader = false) => {
  const response = await api.put(url, data, getHeader);
  return response;
};

export const patch = async (url, data, getHeader = false) => {
  const response = await api.patch(url, data, getHeader);
  return response;
};

// Note: delete is a reserved word
export const remove = async (url, headers) => {
  const response = await api.delete(url, headers);
  return response;
};

