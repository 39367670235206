/* eslint-disable */
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Suspense, lazy} from 'react';
import { ToastContainer } from 'react-toastify';
import Loader from './components/common/Loader';
import 'react-toastify/dist/ReactToastify.css';
import DemoPage from './pages/DemoPage';
import NotFoundPage from './pages/NotFoundPage';
import LoginPage from './pages/LoginPage';
import TripDetailsPage from './pages/TripDetailsPage';
import TripDriverPage from './pages/TripDriverPage';
import TripSummaryPage from './pages/TripSummaryPage';
import TripConfirmedPage from './pages/TripConfirmedPage';
// import DashboardPage from './pages/DashboardPage';
const DashboardPage = lazy(() => import('./pages/DashboardPage'));
import ProfilePage from './pages/ProfilePage';
import ProfileEditPage from './pages/ProfileEditPage';
import PaymentsPage from './pages/PaymentsPage';
import SingleTripPage from './pages/SingleTripPage';
import TripPaymentFailedPage from './pages/TripPaymentFailedPage';
import TripPaymentResponsePage from './pages/TripPaymentResponsePage';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<DashboardPage />} />
            <Route path="/payments" element={<PaymentsPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/profile/edit" element={<ProfileEditPage />} />
            <Route path="/trip/:id/view" element={<SingleTripPage />} />
            <Route path="/trip/:id/add" element={<TripDetailsPage />} />
            <Route path="/trip/add" element={<TripDetailsPage />} />
            <Route path="/trip/:id/driver" element={<TripDriverPage />} />
            <Route path="/trip/:id/summary" element={<TripSummaryPage />} />
            <Route path="/trip/:id/confirmed" element={<TripConfirmedPage />} />
            <Route path="/trip/payments" element={<TripPaymentResponsePage />} />
            <Route path="/trip/:id/payment-failed" element={<TripPaymentFailedPage />} />
            <Route path="/demo" element={<DemoPage />} />
            <Route path="/:slug/" element={<NotFoundPage />} />
            <Route path="/:slug/:slug2" element={<NotFoundPage />} />
            <Route path="/:slug/:slug2/:slug3" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
      <Loader />
      <ToastContainer />
    </div>
  );
}

export default App;

