import React from 'react'
import '../../Loader.css'
import { useSelector } from 'react-redux';

export default function Loader() {
    const loader = useSelector((state) => state.app.loader);

    return (
        <div id="loader-div" style={{ display: loader ? '' : 'none' }}>
            <div id="bars1">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    )
}
