import React from 'react'

export default function TripRunningPaymentConfirmation({ modalId = "", startPayment, openModalRef = "" }) {
    return (
        <div className="modal fade " id={`tripPaymentConfirmation${modalId}`} tabIndex="-1" aria-labelledby={`tripPaymentConfirmationLabel${modalId}`} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="submitted-trip-modal text-center">
                            <img src="/images/icons/info-blue.svg" width="56.63" height="auto" alt="clock in" />
                            <div>
                                <h4>Trip still Running!</h4>
                                <h6>Do you want to continue?</h6>
                            </div>
                            <div className="py-3">
                                <button className="btn btn-primary py-2 px-5 mx-2" onClick={() => startPayment()} type="button" data-bs-dismiss="modal" aria-label="Close">Ok</button>
                                <button className="btn btn-warning py-2 px-5 mx-2" type="button" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                            </div>
                        </div>
                    </div>
                    {
                        openModalRef ? <p ref={openModalRef} data-bs-toggle="modal" data-bs-target="#tripPaymentConfirmation"></p> : null
                    }
                </div>
            </div>
        </div>
    )
}
