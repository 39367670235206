import React, { Fragment, useEffect } from 'react'
import DashboardFooter from '../components/dashboard/DashboardFooter'
import DashboardNavbar from '../components/dashboard/DashboardNavbar'
import DashboardSidebar from '../components/dashboard/DashboardSidebar'
import SingleTripMainComponent from '../components/auth/SingleTripMainComponent'
import { authCheck } from '../services/auth'
import { useNavigate, useLocation } from 'react-router-dom'

export default function SingleTripPage() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!authCheck()) {
      return navigate('/login?next=' + location.pathname);
    }
  }, []);
  return (
    <Fragment>
      {authCheck() ? <section className="dashboard-main-section">
        <DashboardSidebar />
        <div className="dashboard-right-part">
          <DashboardNavbar />
          <SingleTripMainComponent />
          <DashboardFooter />
        </div>
      </section> : null}
    </Fragment>
  )
}
