import React, { Fragment, useEffect } from 'react'
import DashboardFooter from '../components/dashboard/DashboardFooter'
import DashboardNavbar from '../components/dashboard/DashboardNavbar'
import DashboardSidebar from '../components/dashboard/DashboardSidebar'
import CustomerProfileMainComponent from '../components/auth/CustomerProfileMainComponent'
import { authCheck } from '../services/auth'
import { useNavigate } from 'react-router-dom'

export default function ProfilePage() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!authCheck()) {
      return navigate('/login');
    }
  }, []);
  return (
    <Fragment>
      {authCheck() ? <section className="dashboard-main-section">
        <DashboardSidebar />
        <div className="dashboard-right-part">
          <DashboardNavbar />
          <CustomerProfileMainComponent />
          <DashboardFooter />
        </div>
      </section> : null}
    </Fragment>
  )
}
