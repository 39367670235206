import React from 'react'
import ExistingUserForm from './ExistingUserForm';
import NewUserForm from './NewUserForm';
export default function PhoneVerifyMainComponent({ verifyOtp, sendOtp, setVerifyOtp, setEmail, setPhoneNumber, setUserPreference, userPreference, setFirstName, setLastName }) {

    return (
        <div className={`col-lg-6 ${verifyOtp ? 'd-none' : ''}`}>
            <div className="container p-0-mob">
                <div className="right-part">
                    <p className="head-para">#1 Phone number to be verified</p>
                    <p className="choose-preference-para">Choose the user preference</p>
                    <ul className="nav">
                        <li className="nav-item" onClick={() => setUserPreference('existing')}>
                            <div className={`nav-link ${userPreference === 'existing' ? 'active' : ''}`} id="existing-user-tab" data-bs-toggle="tab"
                                data-bs-target="#existing-user" type="button" role="tab"
                                aria-controls="existing-user" aria-selected="true">
                                <div className="radio">
                                    <div></div>
                                </div>
                                <span>Existing User</span>
                            </div>
                        </li>
                        <li className="nav-item" onClick={() => setUserPreference('new')}>
                            <div className={`nav-link ${userPreference === 'new' ? 'active' : ''}`} id="new-user-tab" data-bs-toggle="tab" data-bs-target="#new-user" type="div" role="tab" aria-controls="new-user" aria-selected="false">
                                <div className="radio">
                                    <div></div>
                                </div>
                                <span>New User</span>
                            </div>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <ExistingUserForm sendOtp={sendOtp} setVerifyOtp={setVerifyOtp} setPhoneNumber={setPhoneNumber} active={userPreference === 'existing'} />
                        <NewUserForm sendOtp={sendOtp} setVerifyOtp={setVerifyOtp} setEmail={setEmail} setPhoneNumber={setPhoneNumber} setFirstName={setFirstName} setLastName={setLastName} setUserPreference={setUserPreference} active={userPreference === 'new'} />
                    </div>
                </div>
            </div>
        </div>
    )
}
