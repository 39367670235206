import React from 'react'
import {useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { sidebarCollapse } from '../../reducer/app-reducer';
export default function DashboardNavbar() {
    const user = useSelector((state) => state.auth.auth_user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const goToProfile = () => {
        navigate("/profile", {replace: true});
    }

    return (
        <nav className="dashboard-nav-main">
            <div className="container common-dashboard-container">
                <div className="navbar-inner-div">
                    <div className="left-div" onClick={() => dispatch(sidebarCollapse())}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg" width="26" height="20" viewBox="0 0 26 20"
                            fill="none">
                            <path d="M2 18.3571H24.2222M2 1.69043H24.2222H2ZM2 10.0238H13.1111H2Z" stroke="#3F3D56"
                                strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <div className="right-div">
                        <div className="user-img-name-div" onClick={goToProfile}>
                            <div className="nav-pro-img-div cursor-pointer">
                                <img src={user.profile_image || "/images/user.png"} className="img-fluid" width="185" height="58" />
                            </div>
                            <span className="cursor-pointer">{user.full_name}</span>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}
