const getBaseUrl = () => process.env.REACT_APP_API_URL;

export const getUrl = (type) => {
  const baseUrl = getBaseUrl()+'/api';
  switch (type) {
    case 'register':
      return `${baseUrl}/customer/register`;
    case 'verify-phone':
      return `${baseUrl}/customer/verify-phone`;
    case 'verify-details':
      return `${baseUrl}/customer/verify-details`;
    case 'pickup-locations':
      return `${baseUrl}/customer/pickup-locations`;
    case 'user':
      return `${baseUrl}/customer`;
    case 'profile':
      return `${baseUrl}/customer/profile`;
    case 'save-device-token':
      return `${baseUrl}/customer/save-device-token`;
    case 'login':
      return `${baseUrl}/customer/login`;
    case 'trips':
      return `${baseUrl}/customer/trips`;
    case 'draft-trips':
      return `${baseUrl}/customer/draft/trips`;
    case 'trip-payments':
      return `${baseUrl}/customer/trip-payments`;
    case 'merchants':
      return `${baseUrl}/customer/merchants`;
    case 'stats':
      return `${baseUrl}/customer/stats`;
    default:
      return baseUrl;
  }
};