import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <div>
      <section className="thankyou-main-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <img
                src="/images/icons/404-error.svg"
                alt="Error"
                className="img-fluid mx-auto d-block"
              />
              <h1>The page you’re looking for, doesn’t exist!</h1>
              <p className="text-para 404-error-para ">
                Looks like you hit the wrong URL, please check or follow{" "}
                <br className="d-none d-lg-block" /> these options below
              </p>
              <Link to="/" className="request-another-btn">
                Go to Your Dashboard
              </Link>
              <a className="goto-dashboard cursor-pointer" onClick={() => navigate(-1)}>
                Go Back
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
