import React, { useEffect, useState } from "react";
import { get, handleException, post } from "../../network/requests";
import { getUrl } from "../../network/url";
import { authHeader } from "../../services/auth";
import { useDispatch, useSelector } from "react-redux";
import { loader, loaderStop } from "../../reducer/app-reducer";
import { notify } from "../../services/react-toastify";
import { setMerchants } from "../../reducer/auth-reducer";
import { Link, useNavigate, useParams } from "react-router-dom";

export default function ChooseTripDriverComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const merchants = useSelector((state) => state.auth.merchants);
  const [merchantId, setMerchantId] = useState("");
  const [driverId, setDriverId] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [trip, setTrip] = useState(null);
  const driverName = () => {
    let name = "";
    if (driverId && merchantId && merchants) {
      let merchant = merchants.filter(
        (item) => item.id === Number(merchantId)
      )[0];
      if (merchant) {
        let driver = merchant.drivers.filter(
          (item) => item.id === Number(driverId)
        )[0];
        if (driver) {
          name = driver.name;
        }
      }
    }
    return name || "Choose a driver from the list";
  };
  const params = useParams();
  const getDriversWithMerchant = () => {
    dispatch(loader(true)); // start loader
    get(getUrl("merchants"), authHeader())
      .then((response) => {
        let array = Object.values(response.data.data);
        console.log(array);
        dispatch(setMerchants(array));
        dispatch(loader(false)); // stop loader
      })
      .catch((error) => {
        dispatch(loaderStop()); // stop all loaders
        if (!handleException(error) && error.response.data) {
          notify("error", error.response.data.message);
        }
      });
  };

  const handleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleChooseDriver = (e) => {
    if (e.target.classList.contains("invalid")) {
      return;
    }
    e.target
      .closest(".dropdown")
      .querySelector(".dropdown-toggle")
      .classList.add("active");
    setDropdownOpen(!dropdownOpen);
  };

  const updateTripDriver = () => {
    if (driverId) {
      dispatch(loader(true)); // start loader
      post(
        `${getUrl("draft-trips")}/${params.id}/driver`,
        { driver: driverId },
        authHeader()
      )
        .then(() => {
          dispatch(loader(false)); // stop loader
          navigate(`/trip/${params.id}/summary`);
        })
        .catch((error) => {
          dispatch(loaderStop()); // stop all loaders
          if (!handleException(error) && error.response.data) {
            notify("error", error.response.data.message);
          }
        });
    }
  };

  function sortActiveDrivers(a, b) {
    if (a.is_available > b.is_available) return -1;
    if (a.is_available < b.is_available) return 1;
    return 0;
  }

  const getDriversList = () => {
    let result = merchants
      ? merchants.filter((item) => item.id === Number(merchantId))[0]
      : null;
    let drivers = [];
    if (result) {
      drivers = [...result.drivers];
    }
    drivers = [...drivers.sort(sortActiveDrivers)];
    return drivers;
  };

  const getTripInfo = () => {
    dispatch(loader(true)); // start loader
    get(`${getUrl("draft-trips")}/${params.id}`, authHeader())
      .then((response) => {
        dispatch(loader(false)); // stop loader
        if (!response.data.data) {
          navigate("/");
        }
        setTrip(response.data.data);
        setMerchantId(response.data.data.merchant_id);
        setDriverId(response.data.data.driver_id);
        console.log(response.data);
      })
      .catch((error) => {
        dispatch(loaderStop()); // stop all loaders
        if (!handleException(error) && error.response.data) {
          notify("error", error.response.data.message);
        }
      });
  };

  useEffect(() => {
    getDriversWithMerchant();
    getTripInfo(); // get trip data from API
  }, []);

  return (
    <div className="col-lg-6">
      <div className="container common-container">
        <div className="right-part">
          <p className="head-para">#3 Choose merchant and driver</p>
          {trip && merchants ? (
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="existing-user"
                role="tabpanel"
                aria-labelledby="existing-user-tab"
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="each-input-main-div">
                      <label
                        htmlFor="pickLocation"
                        onClick={() =>
                          console.log(merchants, merchantId, getDriversList())
                        }
                      >
                        Select Merchant
                      </label>
                      <select
                        name=""
                        id=""
                        defaultValue={trip.merchant_id}
                        onChange={(e) => setMerchantId(e.target.value)}
                        required
                      >
                        <option value="" className="">
                          Choose the merchant
                        </option>
                        {merchants && merchants.length
                          ? merchants.map((merchant, index) => (
                              <option key={index} value={merchant.id}>
                                {merchant.name}
                              </option>
                            ))
                          : null}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mt-4 mt-md-0">
                    <div className="each-input-main-div">
                      <label htmlFor="dropLocation">Select Driver</label>
                      {
                        <div className="dropdown select-driver-dropdown">
                          <button
                            className="btn dropdown-toggle"
                            onClick={handleDropdown}
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {driverName()}
                          </button>
                          {merchantId ? (
                            <div
                              onClick={(e) => handleChooseDriver(e)}
                              className={`dropdown-menu ${
                                dropdownOpen ? "d-block" : "d-none"
                              }`}
                              aria-labelledby="dropdownMenuButton"
                            >
                              {getDriversList().map((driver, index) => (
                                <span
                                  className={`dropdown-item ${
                                    driver.is_available ? "" : "invalid"
                                  }`}
                                  key={index}
                                  value={driver.id}
                                  onClick={() =>
                                    setDriverId(
                                      driver.is_available ? driver.id : ""
                                    )
                                  }
                                >
                                  {driver.name}
                                </span>
                              ))}
                            </div>
                          ) : null}
                        </div>
                      }
                    </div>
                  </div>
                </div>

                <div className="bottom-btn-main-div pt-3">
                  <Link className="btn back-btn" to={`/trip/${params.id}/add`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="34"
                      height="23"
                      viewBox="0 0 34 23"
                      fill="none"
                    >
                      <path
                        d="M0.906917 12.2079C0.321133 11.6221 0.321133 10.6723 0.906918 10.0866L10.4529 0.540613C11.0386 -0.0451737 11.9884 -0.0451736 12.5742 0.540613C13.16 1.1264 13.16 2.07615 12.5742 2.66193L4.0889 11.1472L12.5742 19.6325C13.16 20.2183 13.16 21.168 12.5742 21.7538C11.9884 22.3396 11.0386 22.3396 10.4529 21.7538L0.906917 12.2079ZM33.6611 12.6472L1.96758 12.6472L1.96758 9.64721L33.6611 9.64722L33.6611 12.6472Z"
                        fill="#4D90FE"
                      />
                    </svg>
                    Back
                  </Link>
                  <button
                    className={`btn process-btn ${driverId ? "active" : ""}`}
                    onClick={() => updateTripDriver()}
                  >
                    Proceed
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="34"
                      height="23"
                      viewBox="0 0 34 23"
                      fill="none"
                    >
                      <path
                        d="M33.3641 12.7079C33.9499 12.1221 33.9499 11.1723 33.3641 10.5866L23.8181 1.04061C23.2323 0.454826 22.2826 0.454826 21.6968 1.04061C21.111 1.6264 21.111 2.57615 21.6968 3.16193L30.1821 11.6472L21.6968 20.1325C21.111 20.7183 21.111 21.668 21.6968 22.2538C22.2826 22.8396 23.2324 22.8396 23.8181 22.2538L33.3641 12.7079ZM0.609863 13.1472L32.3034 13.1472L32.3034 10.1472L0.609863 10.1472L0.609863 13.1472Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="new-user"
                role="tabpanel"
                aria-labelledby="new-user-tab"
              >
                ..2.
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
